import React, { useEffect, useState } from "react";
import { getDoctorAppointmentListing } from "../../services/DashboardAsyncFunctions";
import AppointmentCard from "../components/cards/AppointmentCard";
import Tab from "react-bootstrap/Tab";
import toast from "react-hot-toast";
import { Nav, Spinner } from "react-bootstrap";
import { APPOINTMENTS_EVENTS } from "../../utils/content";
import eventBus from "../../services/Eventbus";

function Appointments() {
  const [key, setKey] = useState("upcomming");
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPagePast, setCurrentPagePast] = useState(0);
  const [currentPageUrgent, setCurrentPageUrgent] = useState(0);
  const [limit] = useState(10);
  const [hasMoreUpcomingData, setHasMoreUpcomingData] = useState(true);
  const [hasMorePastData, setHasMorePastData] = useState(true);
  const [hasMoreUrgentData, setHasMoreUrgentData] = useState(true);
  const [upcomingAppointments, setUpcomingAppointments] = useState(null);
  const [pastAppointments, setPastAppointments] = useState(null);
  const [urgentAppointments, setUrgentAppointments] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  async function getUpcomingAppointment() {
    // if (!hasMoreUpcomingData) return;
    setIsLoading(true);
    try {
      let response = await getDoctorAppointmentListing(
        "upComing",
        currentPage,
        limit
      );
      let updatedList = upcomingAppointments
        ? [...upcomingAppointments, ...response.data.data.appointments]
        : [...response.data.data.appointments];
      setUpcomingAppointments([...updatedList]);
      setHasMoreUpcomingData(
        response.data.data.appointments.length < limit ? false : true
      );
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }
  async function getPastAppointment() {
    // if (!hasMoreUpcomingData) return;
    setIsLoading(true);
    try {
      let response = await getDoctorAppointmentListing(
        "past",
        currentPagePast,
        limit
      );
      let updatedList = pastAppointments
        ? [...pastAppointments, ...response.data.data.appointments]
        : [...response.data.data.appointments];
      setPastAppointments([...updatedList]);
      setHasMorePastData(
        response.data.data.appointments.length < limit ? false : true
      );
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }
  async function getUrgentAppointment() {
    // if (!hasMoreUpcomingData) return;
    setIsLoading(true);
    try {
      let response = await getDoctorAppointmentListing(
        "urgentCare",
        currentPageUrgent,
        limit
      );
      let updatedList = urgentAppointments
              ? [...urgentAppointments, ...response.data.data.appointments]
        : [...response.data.data.appointments];
      setUrgentAppointments([...updatedList]);
      setHasMoreUrgentData(
        response.data.data.appointments.length < limit ? false : true
      );
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }
  function loadMoreAppointments(type) {
    if (type == "upcoming") {
      setCurrentPage((prev) => prev + 1);
    } else if (type == "past"){
      setCurrentPagePast((prev) => prev + 1);
    } else {
      setCurrentPageUrgent((prev) => prev + 1)
    }
  }

  useEffect(() => {
    getPastAppointment();
  }, [currentPagePast]);

  useEffect(() => {
    getUpcomingAppointment();
  }, [currentPage]);

  useEffect(() => {
    getUrgentAppointment();
  }, [currentPageUrgent]);

  useEffect(() => {
    let eventId = eventBus.on(
      APPOINTMENTS_EVENTS.GET_UPCOMING_APPOINTMENTS,
      getUpcomingAppointment
    );
    return () => {
      eventBus.removeListener(eventId);
    };
  }, []);

  return (
    <div>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Appointments</h2>
        </div>
      </div>
      <Tab.Container defaultActiveKey={key}>
        <div className="card-action tab-container card-tabs mt-3 mt-sm-0">
          <Nav as="ul" className="nav nav-tabs" role="tablist">
            <Nav.Item className="nav-item" onSelect={(k) => setKey(k)}>
              <Nav.Link
                className="nav-link"
                data-toggle="tab"
                to="#bounce"
                role="tab"
                eventKey="upcomming"
              >
                Upcoming Appointments
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item" onSelect={(k) => setKey(k)}>
              <Nav.Link
                className="nav-link"
                data-toggle="tab"
                to="#bounce"
                role="tab"
                eventKey="past"
              >
                Past Appointments
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav-item" onSelect={(k) => setKey(k)}>
              <Nav.Link
                className="nav-link"
                data-toggle="tab"
                to="#bounce"
                role="tab"
                eventKey="urgent"
              >
                Urgent Care
              </Nav.Link>
            </Nav.Item>
      
          </Nav>
        </div>

        <div className="card-body px-0">
          <Tab.Content>
            <Tab.Pane eventKey="upcomming">
              {upcomingAppointments &&
                upcomingAppointments.map((item) => (
                  <AppointmentCard
                    key={item._id}
                    patientName={item?.bookingFor==="familyMember" ? item?.member?.name : item?.user?.name}
                    status={item.status}
                    appointmentId={item._id}
                    patientSex={item.user?.gender}
                    patientEmail={item.user?.email}
                    patientImage={item.user?.image}
                    timeSlot={item?.timeSlots?.[0]}
                    method={item?.method}
                    appointmentType={"upcoming"}
                    type={item?.bookingFor==="familyMember" ? "Family Member" : "Self"}
                  />
                ))}
              {upcomingAppointments && upcomingAppointments.length == 0 && (
                <div className="text-center">
                  <h3>No Data</h3>
                </div>
              )}
              {isLoading && (
                <div className="d-flex align-items-center justify-content-center mb-3">
                  <Spinner animation="border" variant="info"/>
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {hasMoreUpcomingData && !isLoading && (
                  <button
                    className="fs-14 p-2 px-3 btn-warning btn btn-xs m-auto"
                    onClick={() => loadMoreAppointments("upcoming")}
                  >
                    Load more
                  </button>
                )}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="past">
              {pastAppointments &&
                pastAppointments.map((item) => (
                  <AppointmentCard
                    key={item._id}
                    patientName={item?.bookingFor==="familyMember" ? item?.member?.name : item?.user?.name}
                    status={item.status}
                    patientSex={item.user?.gender}
                    patientEmail={item.user?.email}
                    patientImage={item.user?.image}
                    appointmentId={item._id}
                    timeSlot={item?.confirmSlotTime}
                    method={item?.method}
                    appointmentType={"past"}
                    type={item?.bookingFor==="familyMember" ? "Family Member" : "Self"}
                  />
                ))}
              {pastAppointments && pastAppointments.length == 0 && (
                <div className="text-center">
                  <h3>No Data</h3>
                </div>
              )}
              {isLoading && (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner animation="border" variant="info" />
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {hasMorePastData && !isLoading && (
                  <button
                    className="fs-14 p-2 px-3 btn-warning btn btn-xs m-auto"
                    onClick={() => loadMoreAppointments("past")}
                  >
                    Load more
                  </button>
                )}
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="urgent">
              {urgentAppointments &&
                urgentAppointments.map((item) => (
                  <AppointmentCard
                    key={item._id}
                    patientName={item?.bookingFor==="familyMember" ? item?.member?.name : item?.user?.name}
                    status={item.status}
                    patientSex={item.user?.gender}
                    patientEmail={item.user?.email}
                    patientImage={item.user?.image}
                    appointmentId={item._id}
                    timeSlot={item?.createdAt}
                    method={item?.method}
                    appointmentType={"urgent"}
                    type={item?.bookingFor==="familyMember" ? "Family Member" : "Self"}
                  />
                ))}
              {urgentAppointments && urgentAppointments.length == 0 && (
                <div className="text-center">
                  <h3>No Data</h3>
                </div>
              )}
              {isLoading && (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner animation="border" variant="info" />
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {hasMoreUrgentData && !isLoading && (
                  <button
                    className="fs-14 p-2 px-3 btn-warning btn btn-xs m-auto"
                    onClick={() => loadMoreAppointments("urgent")}
                  >
                    Load more
                  </button>
                )}
              </div>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
}

export default Appointments;
