import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import ChatCard from "./ChatCard";
import PerfectScrollbar from "react-perfect-scrollbar";
import { getConversationList } from "../../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";

function ConversationList({type}) {
  const [isLoading, setIsLoading] = useState(true);
  const [chatList, setChatList] = useState(null);
  const [limit] = useState(20);
  const [hasMorePastData, setHasMorePastData] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  let totalItems = 0;

  async function getUserChatList() {
    setIsLoading(true);
    try {
      const response = await getConversationList({ page: currentPage, limit: limit,filter:type });
      console.log(response);
      let updatedList = chatList
        ? [...chatList, ...response.data.data.conversations]
        : [...response.data.data.conversations];
      setChatList([...updatedList]);
      setHasMorePastData(
        response.data.data.conversations.length < limit ? false : true
      );
      totalItems = response.data.data.total;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUserChatList();
  }, [currentPage]);

  function loadMoreChat() {
    setCurrentPage((prev) => prev + 1);
  }

  return (
    <Card className="chat-list shadow-md">
        {chatList &&
          chatList.map((item) => (
            <ChatCard
              key={item._id}
              updatedAt={item.updatedAt}
              lastMessage={item.message}
              type={item.messageType}
              conversationData={item}
              unreadCount={item?.unReadMessage}
              userName={item?.receiver}
            />
          ))}
        {
          (!chatList || chatList.length == 0) && !isLoading &&
          <h5 className="text-center">No Data</h5>
        }
        {isLoading && <div className="d-flex align-items-center justify-content-center mt-2"><Spinner animation="border" variant="info" /> </div>}
        {!isLoading && hasMorePastData && <div className="d-flex align-items-center justify-content-center mt-2">
          <button
            className="fs-14 p-2 px-3 btn-warning btn btn-xs m-auto"
            onClick={() => loadMoreChat()}
          >
            Load more
          </button>
        </div>}
    </Card>
  );
}

export default ConversationList;
