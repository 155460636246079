import React, { Fragment, useEffect, useReducer, useState,useRef } from "react";
import { Button, Modal, Tab, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getProfileFetch } from "../../services/DashboardAsyncFunctions";
import { connect, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { zodValidationSchemaEditProfile } from "../../utils/zodschema";
import { specialityCategories } from "../../utils/content";
import GooglePlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
  } from "react-google-places-autocomplete";
  import addFileToS3Bucket from "../../services/Aws";
  import toast from "react-hot-toast";
  import {
    editUser,
	getUserToken,
	registerUser,
	saveTokenInLocalStorage,
	slotBook,
  } from "../../services/AuthService";
  import CustomSelect from "../components/CustomSelect";
import DummyUser from "../../images/dummy-profile.png";
import LoadingImage from "../../images/loading-image.webp";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { profileUpdateAction } from "../../store/actions/AuthActions";

const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessage':
			return { ...state, sendMessage: !state.sendMessage }		
		case 'postModal':
			return { ...state, post: !state.post }
		case 'linkModal':
			return { ...state, link: !state.link }		
		case 'cameraModal':
			return { ...state, camera: !state.camera }		
		case 'replyModal':
			return { ...state, reply: !state.reply }
		default:
			return state	
	}	
}

const AppProfile = () => {
  const navigate = useHistory();
	const [state] = useReducer(reducer, initialState);
	const [profileData, setProfileData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

	async function getProfileData(){
    setLoadingData(true)
		try{
			const response = await getProfileFetch();
			setProfileData(response.data?.data);
      setLoadingData(false)
		}catch(error){
			console.log(error,"error")
      setLoadingData(false)
		}
	}

  const [selectedOption, setSelectedOption] = useState(null);
  const [code, setCode] = useState("+1");
  const [googleAddress, setGoogleAddress] = useState(null);
  const [errorAddress, setErrorAddress] = useState("");
  const [apiError, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [gender, setGender] = useState("Male");
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const imageRef = useRef();
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [locationDetails, setLocationDetails] = useState({
    lat: null,
    lng: null,
    label: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(zodValidationSchemaEditProfile),
	// defaultValues: {},
  });
  const dispatch = useDispatch();

  console.log(errors,"sjhfjdsjfahsdfhkjsdhfjkh")

  // Update the form when profileData changes
  useEffect(() => {
    if (profileData) {
      const newProfileData = {
        name: profileData?.name,
        phoneNumber: profileData?.phoneNumber,
        countryCode: profileData?.countryCode,
        experience: profileData?.experience,
        qualification: profileData?.qualification,
        areaOfExpertise: profileData?.areaOfExpertise,
        description: profileData?.description,
        hospitalName: profileData?.hospital,
        address: profileData?.address,
        hospital: profileData?.hospital,
        gender: profileData?.gender,
        image: profileData?.image,
      };

      const filteredCategories = specialityCategories.filter(category =>
        profileData?.specialty?.includes(category.value)
      );

      setCode(profileData?.countryCode);
      setSelectedOption(filteredCategories);
      setProfileImageUrl(profileData?.image);

      // Update form values using reset
      reset(newProfileData);
    }
  }, [profileData, reset]);

  const handleSelectChange = (selectedOption) => {
    console.log(selectedOption, "selected options");
    setSelectedOption(selectedOption);
  };

  const handleAddress = async (place) => {
    try {
      setGoogleAddress(place.label);
      const results = await geocodeByAddress(place.label);
      console.log(results, "results");

      const latLng = await getLatLng(results[0]);

      const newLocationDetails = {
        lat: latLng.lat,
        lng: latLng.lng,
        label: place.label,
      };

      setLocationDetails(newLocationDetails);
    } catch (error) {
      console.error("Error getting address details:", error);
    }
  };

  async function handleImageUpload(files) {
    if (!files?.[0]) return;
    console.log(files);
    setImageUploadLoading(true);
    try {
      let response = await addFileToS3Bucket(files[0], "appointment");
      setProfileImageUrl(response.Location);
    } catch (error) {
      toast.error("Error occured while uploading media!");
      setProfileImageUrl(null);
    } finally {
      setImageUploadLoading(false);
    }
  }

  const onSubmit = async (data) => {
    if (!selectedOption) {
      toast.error("Speciality is required!");
      return;
    } else {
      setErrorAddress("");
      const {
        phoneNumber,
        experience,
        qualification,
        areaOfExpertise,
        description,
        hospitalName,
        name,
      } = data;

      const specialty = selectedOption.map((item) => item.value);
      
      const formData = {
        name,
        phoneNumber: phoneNumber + "",
        countryCode: code,
        experience: experience + "",
        qualification: qualification,
        areaOfExpertise: areaOfExpertise,
        description: description,
        specialty: specialty,
        address: locationDetails.label ? locationDetails.label : "Delhi",
        hospital: hospitalName,
        image: profileImageUrl,
      };

      setIsLoading(true);

      try {
        let response = await editUser(formData);
        if (response.status === 200) {
          toast.success("Edit successfull", { duration: 1000 });
          navigate.push("/");
          localStorage.setItem("_anytime_user_", JSON.stringify({email: response.data.data.email,id: response.data.data._id, name:response.data.data.name, isLoggedIn: true, documentStatus: response.data.data.detailStatus, image: response.data.data.image}));
          reset();
          dispatch(profileUpdateAction(true));
        }
      } catch (error) {
        // console.log(error.response.data.message);
        // toast.error(error.response.data.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

	  useEffect(() => {
		getProfileData();
	},	[]);

	console.log(profileData);

	return (
		<Fragment>
		  <div className="row">
			<div className="col-xl-8">
			  <div className="card">
				<div className="card-body">
				  <div className="profile-tab">
					<div className="custom-tab-1">
						<Tab.Container defaultActiveKey='About'>					
							<Nav as='ul' className="nav nav-tabs">
								<Nav.Item as='li'i className="nav-item">
									<Nav.Link to="#about-me"  eventKey='About'>About Me</Nav.Link>
								</Nav.Item>
								<Nav.Item as='li' className="nav-item">
									<Nav.Link to="#profile-settings" eventKey='Setting'>Setting</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content>
								<Tab.Pane id="about-me" eventKey='About'>
									<div className="profile-about-me pt-3">
									<div className="profile-personal-info">
										<h4 className="text-primary mb-4">
											Personal Information
										</h4>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500"> Name<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>{loadingData ? "Loading..." : profileData?.name}</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">Phone Number<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>{loadingData ? "Loading..." : profileData?.phoneNumber}</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">Email<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>{loadingData ? "Loading..." : profileData?.email}</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">Hospital<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>{ loadingData ? "Loading..." : profileData?.hospital}</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">Area of Expertise<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>{loadingData ? "Loading..." : profileData?.areaOfExpertise}</span>
											</div>
										</div>
										<div className="row mb-2">
											<div className="col-3">
												<h5 className="f-w-500">Year Experience<span className="pull-right">:</span></h5>
											</div>
											<div className="col-9">
												<span>{ loadingData ? "Loading..." : profileData?.experience}</span>
											</div>
										</div>
									</div>
									</div>
								</Tab.Pane>
								<Tab.Pane id="profile-settings" eventKey='Setting'>
									<div className="pt-3">
										<div className="settings-form">
											<h4 className="text-primary">Account Setting</h4>
											<div className="auth-form">
                      <h2 className="text-center mb-4">Edit Account</h2>

                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="profile-img-container relative">
                          {!imageUploadLoading && (
                            <img
                              src={
                                profileImageUrl ? profileImageUrl : DummyUser
                              }
                              width={100}
                              height={100}
                              className="signup-img"
                            />
                          )}
                          {imageUploadLoading && (
                            <img
                              src={LoadingImage}
                              width={100}
                              height={100}
                              className="signup-img"
                            />
                          )}

                          <input
                            ref={imageRef}
                            type="file"
                            accept="image/*"
                            className="d-none"
                            onChange={(e) => handleImageUpload(e.target.files)}
                          />
                          <i
                            className="flaticon-381-edit absolute edit-img-icon"
                            onClick={() => imageRef.current.click()}
                          ></i>
                        </div>
                        <div className="form-group">
                          <label className="mb-1 mt-3 ">
                            <strong>Full Name</strong>
                          </label>
                          <input
                            id="name"
                            type="text"
                            className="form-control global-input-field"
                            {...register("name")}
                          />
                        </div>
                        {errors.name && (
                          <div className="text-danger fs-12">
                            {errors.name?.message}
                          </div>
                        )}

                        <div className="form-group">
                          <label className="mb-1 mt-3 ">
                            <strong>Gender</strong>
                          </label>
                          <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            className="form-control global-input-field"
                          >
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                        {errors.gender && (
                          <div className="text-danger fs-12">
                            {errors.gender?.message}
                          </div>
                        )}
                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Experience </strong>{" "}
                            <span className="fs-12">(In Years)</span>
                          </label>
                          <input
                            id="experience"
                            type="number"
                            className="form-control global-input-field"
                            {...register("experience", { valueAsNumber: true })}
                          />
                          {errors.experience && (
                            <div className="text-danger fs-12">
                              {errors.experience?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Qualification</strong>
                          </label>
                          <input
                            id="qualification"
                            type="text"
                            className="form-control global-input-field"
                            {...register("qualification")}
                          />
                          {errors.qualification && (
                            <div className="text-danger fs-12">
                              {errors.qualification?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Area of Expertise</strong>
                          </label>
                          <input
                            id="areaOfExpertise"
                            type="text"
                            className="form-control global-input-field"
                            {...register("areaOfExpertise")}
                          />
                          {errors.areaOfExpertise && (
                            <div className="text-danger fs-12">
                              {errors.areaOfExpertise?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Hospital Name</strong>
                          </label>
                          <input
                            id="hospitalName"
                            type="text"
                            className="form-control global-input-field"
                            {...register("hospitalName")}
                          />
                          {errors.hospitalName && (
                            <div className="text-danger fs-12">
                              {errors.hospitalName?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-1 ">
                            <strong>Description</strong>
                          </label>
                          <input
                            id="description"
                            type="text"
                            className="form-control global-input-field"
                            {...register("description")}
                          />
                          {errors.description && (
                            <div className="text-danger fs-12">
                              {errors.description?.message}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-2 ">
                            <strong>Speciality </strong>
                          </label>
                          <CustomSelect
                            selectedOption={selectedOption}
                            handleSelectChange={handleSelectChange}
                            listItems={specialityCategories}
                            isMulti={true}
                          />
                        </div>

                        <div className="form-group">
                          <label className="mb-1 mt-3 ">
                            <strong>Phone number</strong>
                          </label>
                          <div className="d-flex">
                            <div className="relative">
                              <PhoneInput
                                country={"us"}
                                enableSearch={true}
                                value={code}
                                onChange={(phone) => setCode(phone)}
                              />
                            </div>

                            <div className="w-100">
                              <input
                                id="phoneNumber"
                                type="number"
                                className="form-control global-input-field  sign-phone"
                                {...register("phoneNumber", {
                                  valueAsNumber: true,
                                })}
                              />
                            </div>
                          </div>
                          {errors.phoneNumber && (
                            <div className="text-danger fs-12">
                              {errors.phoneNumber?.message}
                            </div>
                          )}
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="submit"
                            disabled={isLoading}
                            className="btn btn-primary btn-block"
                          >
                            {isLoading ? "Processing..." : "Submit"}
                          </button>
                        </div>
                      </form>
                    </div>
										</div>
									</div>
								</Tab.Pane>
							</Tab.Content>	
						</Tab.Container>		
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		  {/* send Modal */}
			  <Modal className="modal fade" show={state.sendMessage} onHide={()=>dispatch({type:'sendMessage'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Send Message</h5>
						<Button variant="" type="button" className="close" data-dismiss="modal" onClick={() => dispatch({type:'sendMessage'})}>
							<span>×</span>
						</Button>
					</div>
					<div className="modal-body">
						<form className="comment-form" onSubmit={(e) => { e.preventDefault(); dispatch({type:'sendMessage'}); }}>
							<div className="row">
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="author" className="text-black font-w600">  Name <span className="required">*</span> </label>
										<input type="text" className="form-control" defaultValue="Author" name="Author" placeholder="Author" />
									</div>
								</div>
								<div className="col-lg-6">
									<div className="form-group mb-3">
										<label htmlFor="email" className="text-black font-w600"> Email <span className="required">*</span></label>
										<input type="text" className="form-control" defaultValue="Email" placeholder="Email" name="Email"/>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group mb-3">
										<label htmlFor="comment" className="text-black font-w600">Comment</label>
										<textarea rows={4} className="form-control" name="comment" placeholder="Comment" defaultValue={""}/>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group mb-3">
										<input type="submit" value="Post Comment" className="submit btn btn-primary" name="submit"/>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Modal>
		  {/* Post Modal */}
			  <Modal show={state.post} className="modal fade" id="postModal" onHide={() => dispatch({type:'postModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Post</h5>
						<Button variant=""  type="button" className="close" data-dismiss="modal" onClick={() => dispatch({type:'postModal'})} >
							<span>×</span>
						</Button>						
					</div>
					<div className="modal-body">
						<textarea name="textarea" id="textarea" cols={30} rows={5} className="form-control mb-2 bg-transparent" placeholder="Please type what you want...." defaultValue={""}/>
						<Link className="btn btn-primary btn-rounded mt-1" to="/app-profile">Post</Link>
					</div>
				</div>
			</Modal>
			 {/* Link Modal */}
			  <Modal show={state.link}  className="modal fade post-input" id="linkModal" onHide={() => dispatch({type:'linkModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Social Links</h5>
						<button type="button" className="close" data-dismiss="modal" onClick={() => dispatch({type:'linkModal'})}>
							<span>×</span>
						</button>
					</div>
					<div className="modal-body">
						<Link className="btn-social me-1 facebook" to="/app-profile"><i className="fa fa-facebook-f" /></Link>
						<Link className="btn-social me-1 google-plus" to="/app-profile"> <i className="fa fa-google-plus" /></Link>
						<Link className="btn-social me-1 linkedin" to="/app-profile"><i className="fa fa-linkedin" /></Link>
						<Link className="btn-social me-1 instagram" to="/app-profile"> <i className="fa fa-instagram" /></Link>
						<Link className="btn-social me-1 twitter" to="/app-profile"><i className="fa fa-twitter" /></Link>
						<Link className="btn-social me-1 youtube" to="/app-profile"><i className="fa fa-youtube" /></Link>
						<Link className="btn-social whatsapp" to="/app-profile"><i className="fa fa-whatsapp" /></Link>
					</div>
				</div>
			</Modal>
			 {/* Camera Modal */}
			  <Modal show={state.camera}  className="modal fade" id="cameraModal" onHide={() => dispatch({type:'cameraModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Upload images</h5>
						<button type="button" className="close" data-dismiss="modal" onClick={() => dispatch({type:'cameraModal'})}>
							<span>×</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="input-group custom_file_input mb-3">
							<span className="input-group-text">Upload</span>
							<div className="form-file">
								<input type="file" className="form-file-input form-control" />
							</div>
						</div>
					</div>
				</div>
			</Modal>
			 {/* Reply Modal */}
			  <Modal   show={state.reply}  className="modal fade" id="replyModal" onHide={()=>dispatch({type:'replyModal'})} centered>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Post Reply</h5>
						<button type="button" className="close"  onClick={() => dispatch({type:'replyModal'})}>
							<span>×</span>
						</button>
					</div>
					<div className="modal-body">
						<form>
							<textarea className="form-control" rows="4">Message</textarea>
						</form>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-danger light"  onClick={() => dispatch({type:'replyModal'})}>Close</button>
						<button type="button" className="btn btn-primary">Reply</button>
					</div>
				</div>
			</Modal>
		</Fragment>
	);
};

export default AppProfile