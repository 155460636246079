import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import socketServices from "../../services/SocketServices";
import { SOCKET_GLOBAL_EVENTS } from "../../utils/content";
import AlertClip from "../../images/request-alert.mp3";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  apiCallUrgentRequest,
  respondToUrgentCareRequest,
} from "../../services/DashboardAsyncFunctions";
import { selectConversationForChat } from "../../store/actions/SessionActions";
import { useDispatch } from "react-redux";

let timerLimit = 30;

function IncomingUrgentRequest({ show, onHide, showModal, socketConnected }) {
  const navigate = useHistory();
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(timerLimit);
  const [isRespondingToRequest, setIsRespondingToRequest] = useState(false);
  const [isUserPaying, setIsUserPaying] = useState(false);
  const [requestType, setRequestType] = useState("");
  const audio = new Audio(AlertClip);
  const [requests, setRequests] = useState([]); // Track requests with remainingTime
  const intervalRef = useRef(null);

  const timer = useRef(null);

  async function respondToRequest(status, requestId, type) {
    setIsRespondingToRequest(true);
    if (status == "Accept" && type == "urgentCare") {
      onHide();
      navigate.push(`/video-call/${requestId}`);
      setCountdown(timerLimit);
      localStorage.setItem("video-call-show", true);
      setIsRespondingToRequest(false);
    } else if (status == "Accept" && type == "chat") {
      let response = await respondToUrgentCareRequest({
        status,
        appointmentId: requestId
      });
      setIsRespondingToRequest(false);
      dispatch(
        selectConversationForChat({
          conversationId: response?.data.data.conversation._id,
          name: response?.data.data.conversation.receiver?.name,
          Id: response?.data.data.conversation.receiver?._id,
          image: response?.data.data.conversation.receiver?.image
            ? response?.data.data.conversation.receiver?.image
            : "",
          email: response?.data.data.conversation?.receiver?.email,
        })
      );
      navigate.push("/chat");
      onHide();
    } else if (status == "Reject") {
      onHide();
      clearInterval(timer.current);
      toast.success("The request was rejected!");
      setIsRespondingToRequest(false);
    }
  }

  function removeListeners() {
    socketServices.removeListener(SOCKET_GLOBAL_EVENTS.NO_RESPONSE_FROM_DOCTOR);
    socketServices.removeListener(SOCKET_GLOBAL_EVENTS.RECIEVE_REQUEST);
    socketServices.removeListener(SOCKET_GLOBAL_EVENTS.JOIN_CALL_AFTER_PAYMENT);
    socketServices.removeListener(SOCKET_GLOBAL_EVENTS.REQUEST_TIMED_OUT);
  }

  useEffect(() => {
    if (isUserPaying) {
      timer.current = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer.current);
    };
  }, [isUserPaying]);

  useEffect(() => {
    if (show) {
      intervalRef.current = setInterval(() => {
        setRequests((prevRequests) =>
          prevRequests
            .map((req) =>
              req.remainingTime > 0
                ? { ...req, remainingTime: req.remainingTime - 1 }
                : null
            )
            .filter(Boolean)
        );
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
      audio.pause();
    }

    return () => {
      clearInterval(intervalRef.current);
      audio.pause();
    };
  }, [show]);

  useEffect(() => {
    if (countdown === 0) {
      onHide();
      setCountdown(timerLimit);
    }
  }, [countdown, onHide]);

  useEffect(() => {
    socketServices.on(SOCKET_GLOBAL_EVENTS.NO_RESPONSE_FROM_DOCTOR, (data) => {
      onHide();
      toast("You failed to respond to the request");
      setCountdown(timerLimit);
    });
    socketServices.on(SOCKET_GLOBAL_EVENTS.RECIEVE_REQUEST, (data) => {
      console.log(data);
      showModal();
      fetchData();
      setRequestType(data?.type);
      socketServices.on(SOCKET_GLOBAL_EVENTS.REQUEST_TIMED_OUT, (data) => {
        onHide();
        toast("Patient couldn't complete payment, request timed out.");
        setCountdown(timerLimit);
        clearInterval(timer.current);
        socketServices.removeListener(SOCKET_GLOBAL_EVENTS.REQUEST_TIMED_OUT);
      });
    });
    socketServices.on(SOCKET_GLOBAL_EVENTS.JOIN_CALL_AFTER_PAYMENT, (data) => {
      onHide();
      clearInterval(timer.current);
      setCountdown(timerLimit);
      window.open(data?.data?.url, "_blank");
    });
    return () => {
      removeListeners();
    };
  }, [socketConnected]);

  const fetchData = async () => {
    try {
      const response = await apiCallUrgentRequest();
      const newRequest = response?.data?.data || [];
      audio.play();
      mergeRequests(newRequest);
    } catch (error) {
      console.log(error);
    }
  };

  const mergeRequests = (newData) => {
    setRequests((prevRequests) => {
      const updatedRequests = [...prevRequests];

      newData.forEach((newRequest) => {
        const existingRequest = updatedRequests.find(
          (request) => request.id === newRequest.id
        );

        if (!existingRequest) {
          updatedRequests.push({ ...newRequest, remainingTime: timerLimit });
        }
      });

      return updatedRequests;
    });
  };

  return (
    <>
      <Modal
        className="modal fade"
        backdrop="static"
        centered
        onHide={onHide}
        show={show}
      >
        <div className="animate__animated animate__fadeIn p-3" role="document">
          <Modal.Header>
            <h5 className="">
              {requestType == "urgentCare"
                ? "Urgent Care"
                : "Free Consultation"}{" "}
              Request
            </h5>
          </Modal.Header>
          <Modal.Body>
            {requests.length > 0 ? (
              requests.map((request) => (
                <div key={request._id} className="card mb-3 shadow-sm border-0">
                  <div className="card-body d-flex align-items-center">
                    {/* User Info */}
                    <div className="flex-grow-1">
                      <h5 className="mb-1 text-primary">
                        {request?.user?.name}
                      </h5>
                      <h6 className="text-muted">{request?.user?.email}</h6>
                    </div>

                    {/* Actions */}
                    <div className="d-flex align-items-center gap-2">
                      {!isRespondingToRequest && (
                        <button
                          type="button"
                          className="btn btn-success flex-grow-1"
                          disabled={isUserPaying}
                          onClick={() =>
                            respondToRequest(
                              "Accept",
                              request._id,
                              request.type
                            )
                          }
                        >
                          Accept
                        </button>
                      )}
                      {!isRespondingToRequest && (
                        <button
                          type="button"
                          onClick={() =>
                            respondToRequest(
                              "Reject",
                              request._id,
                              request.type
                            )
                          }
                          disabled={isUserPaying}
                          className="btn btn-warning bg-danger flex-grow-1"
                        >
                          <i className="flaticon-delete-1"></i> Reject
                        </button>
                      )}
                      {isRespondingToRequest && (
                        <button
                          type="button"
                          disabled
                          className="btn btn-secondary flex-grow-1"
                        >
                          Loading...
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="px-4">
                    <p>Reason : {request.reasonVisit}</p>
                  </div>

                  <div className="progress-wrapper p-3">
                    {request.remainingTime > 0 ? (
                      <div className="progress" style={{ height: "30px" }}>
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width: `${
                              timerLimit > 0
                                ? (request.remainingTime / timerLimit) * 100
                                : 0
                            }%`,
                          }}
                          aria-valuenow={request.remainingTime}
                          aria-valuemin="0"
                          aria-valuemax={timerLimit}
                        >
                          {request.remainingTime}s
                        </div>
                      </div>
                    ) : (
                      <p className="text-danger fw-bold mb-0">Expired</p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center py-5">
                <h3 className="text-muted">No incoming requests</h3>
              </div>
            )}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(IncomingUrgentRequest);
