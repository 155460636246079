import React, { useEffect, useState } from "react";
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from "opentok-react";
import {
  respondEndCallRequest,
  respondToUrgentCareRequest,
} from "../../services/DashboardAsyncFunctions";
import { useParams, useHistory } from "react-router-dom";
import OT from "@opentok/client";
import VideoCallFormFill from "../modal/VideoCallFormFill";
import socketServices from "../../services/SocketServices";
import { FcEndCall } from "react-icons/fc";
import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaVideo,
  FaVideoSlash,
  FaStickyNote,
} from "react-icons/fa";
import EndVideoCall from "../modal/EndVideoCall";
import { videoCallAction } from "../../store/actions/SessionActions";
import { useDispatch } from "react-redux";

const VideoCall = () => {
  const { id: appointmentId } = useParams();
  const dispatch = useDispatch();
  const [videoCallShow, setVideoCall] = useState(false);
  const [showCallEndModal, setShowCallEndModal] = useState(false);
  const [showFormFillModal, setShowFormFillModal] = useState(false);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(15 * 60);
  const [startTime, setStartTime] = useState(null);
  const history = useHistory();

  const getSessionDetails = async () => {
    setLoading(true);
    try {
      const response = await respondToUrgentCareRequest({
        status: "Accept",
        appointmentId,
      });

      if (response.status === 200) {
        const callDetail = response.data.data?.callDetail;
        const sessionData = {
          sessionId: callDetail?.sessionId,
          token: callDetail?.token,
        };
        localStorage.setItem("sessionInfo", JSON.stringify(sessionData));
        localStorage.setItem("VideoCallAppointment", appointmentId);
        setSessionInfo(sessionData);
        setLoading(false);
        dispatch(videoCallAction(true));
      } else {
        console.error("Failed to fetch session info");
      }
    } catch (error) {
      console.error("Error fetching session details:", error);
      setLoading(false);
      history.push(`/`);
    }
  };

  useEffect(() => {
    if (startTime !== null) {
      const endTime = new Date(startTime).getTime() + 15 * 60 * 1000;

      const updateTimer = () => {
        const currentTime = new Date().getTime();
        const remainingTime = Math.max(endTime - currentTime, 0);
        setTimeLeft(Math.floor(remainingTime / 1000));
      };
      updateTimer();
      const timerInterval = setInterval(updateTimer, 1000);

      return () => clearInterval(timerInterval);
    }
  }, [startTime]);

  const getSessionDetailsTimeOut = async () => {
    setLoading(true);
    try {
      const response = await respondToUrgentCareRequest({
        status: "Accept",
        appointmentId,
      });
      if (response.status === 200) {
        setStartTime(response.data.data.callStartTime);
        setLoading(false);
        dispatch(videoCallAction(true));
      } else {
        console.error("Failed to fetch session info");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching session details:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    const storedSessionInfo = localStorage.getItem("sessionInfo");
    if (storedSessionInfo) {
      setSessionInfo(JSON.parse(storedSessionInfo));
      setLoading(false);
      getSessionDetailsTimeOut();
    } else {
      getSessionDetails();
    }

    const videoCallStatus = localStorage.getItem("video-call-show");
    setVideoCall(videoCallStatus === "true");
  }, []);

  const endCall = async () => {
    try {
      const response = await respondEndCallRequest({
        appointmentId,
      });
      if (response.status === 200) {
        localStorage.removeItem("sessionInfo");
        localStorage.removeItem("VideoCallAppointment");
        localStorage.setItem("video-call-show", "false");
        setSessionInfo(null);
        setVideoCall(false);
        setShowCallEndModal(true);
        dispatch(videoCallAction(false));
      }
    } catch (error) {
      console.error("Error ending the call:", error);
    }
  };

  const toggleVideo = () => {
    setIsVideoPaused(!isVideoPaused);
  };

  const toggleAudio = () => {
    setIsAudioMuted(!isAudioMuted);
  };

  useEffect(() => {
    if (timeLeft <= 0) {
      endCall();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  console.log("time left", timeLeft);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    socketServices.on("endService", (data) => {
      localStorage.setItem("video-call-show", false);
      setShowCallEndModal(true);
      setVideoCall(false);
      setSessionInfo(null);
      localStorage.removeItem("sessionInfo");
      localStorage.removeItem("VideoCallAppointment");
      dispatch(videoCallAction(false));
    });
  }, []);

  if (loading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      {sessionInfo?.sessionId && videoCallShow ? (
        <div>
          <OTSession
            apiKey="b214461a-83dd-49ad-b409-151d15359291"
            sessionId={sessionInfo.sessionId}
            token={sessionInfo.token}
          >
            <div className="">
              <div className="row justify-content-center">
                <div className="col-12 col-md-12 text-center">
                  <div className="main-video-container position-relative">
                    <OTStreams>
                      <OTSubscriber
                        className="video-call"
                        properties={{
                          width: "100%",
                          height: "85vh",
                          insertMode: "append",
                        }}
                      />
                    </OTStreams>
                    <div className="overlay-name">Participant</div>
                    {loading ? (
                      <h3 className="overlay-name-time">Loading</h3>
                    ) : (
                      <h3 className="overlay-name-time">
                        {formatTime(timeLeft)}
                      </h3>
                    )}
                    <div className="call-controls">
                      <span
                        onClick={() => setShowFormFillModal(true)}
                        style={{
                          color: "yellow",
                          cursor: "pointer",
                          fontSize: "25px",
                        }}
                      >
                        <FaStickyNote />
                      </span>
                      <span
                        onClick={endCall}
                        style={{
                          color: "red",
                          cursor: "pointer",
                          fontSize: "25px",
                        }}
                      >
                        <FcEndCall />
                      </span>
                      <span
                        onClick={toggleVideo}
                        style={{
                          color: "#4f4fff",
                          cursor: "pointer",
                          fontSize: "25px",
                        }}
                      >
                        {isVideoPaused ? <FaVideoSlash /> : <FaVideo />}
                      </span>
                      <span
                        onClick={toggleAudio}
                        style={{
                          color: "green",
                          cursor: "pointer",
                          fontSize: "25px",
                        }}
                      >
                        {isAudioMuted ? (
                          <FaMicrophoneSlash />
                        ) : (
                          <FaMicrophone />
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="self-video-container">
                  <OTPublisher
                    className="video-call"
                    properties={{
                      width: "100%",
                      height: "150px",
                      insertMode: "append",
                      publishVideo: !isVideoPaused,
                      publishAudio: !isAudioMuted,
                    }}
                  />
                  <div className="overlay-name">You</div>
                </div>
              </div>
            </div>
          </OTSession>
        </div>
      ) : (
        <div className="text-center">
          <h3>No Video Call</h3>
        </div>
      )}
      {showFormFillModal && (
        <VideoCallFormFill
          show={showFormFillModal}
          onHide={() => setShowFormFillModal(false)}
          appointmentId={appointmentId}
        />
      )}
      {showCallEndModal && (
        <EndVideoCall
          show={showCallEndModal}
          onHide={() => setShowCallEndModal(false)}
          showForm={() => setShowFormFillModal(true)}
        />
      )}
    </>
  );
};

export default VideoCall;
