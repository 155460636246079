import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getPatientAppointments } from "../../services/DashboardAsyncFunctions";
import { Spinner } from "react-bootstrap";
import { FaClock, FaCalendarAlt, FaMoneyBillAlt } from "react-icons/fa";
import User from "../../images/DummyUser.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function PatientAppointments() {
  const { id: patientId } = useParams();
  const navigate = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const [patientDetails, setPatientDetails] = useState(null);

  async function fetchPatientAppointments() {
    if (!patientId) {
      toast.error("Could not fetch the details");
      return;
    }
    setIsLoading(true);
    try {
      const response = await getPatientAppointments(patientId);
      const data = response?.data?.data || [];
      setAppointments(data);
      if (data.length > 0) {
        setPatientDetails({
          name: data[0]?.user?.name,
          image: data[0]?.user?.image
            ? process.env.REACT_APP_BUCKET_BASE_URL + data[0]?.user?.image
            : User,
          countryCode: data[0]?.user?.countryCode,
          phoneNumber: data[0]?.user?.phoneNumber,
          dateOfBirth: data[0]?.user?.dateOfBirth,
          email: data[0]?.user?.email,
          pastMedicalHistory: data[0]?.user?.pastMedicalHistory,
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Failed to fetch appointments"
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchPatientAppointments();
  }, []);

  return (
    <div className="container mt-4">
      {/* Patient Header */}

      {/* Section Title */}
      <div className="mb-4 text-center">
        <h3 className="text-black font-w600">Past Appointments</h3>
        <p className="text-muted">
          Review all past appointments for this patient
        </p>
      </div>

      {patientDetails && (
        <div className="card shadow-sm mb-4 p-3">
          <div className="row g-3 align-items-center">
            {/* Profile Image */}
            <div className="col-auto">
              <img
                src={patientDetails.image}
                alt={patientDetails.name}
                className="rounded-circle"
                style={{ width: "80px", height: "80px", objectFit: "cover" }}
              />
            </div>

            {/* Profile Details */}
            <div className="col">
              <h4 className="mb-1 text-primary">{patientDetails.name}</h4>
              <p className="mb-1 text-muted">
                <strong>Phone:</strong> +{patientDetails.countryCode}{" "}
                {patientDetails.phoneNumber}
              </p>
              <p className="mb-0 text-muted">
                <strong>Email:</strong> {patientDetails.email}
              </p>
            </div>
          </div>

          {/* Additional Info */}
          <div className="mt-3">
            <p className="mb-1">
              <strong>Date of Birth:</strong> {patientDetails.dateOfBirth}
            </p>

            {/* Past Medical History */}
            {patientDetails.pastMedicalHistory.length > 0 ? (
              <div className="mt-2">
                <strong>Past Medical History:</strong>
                <div className="mt-1">
                  {patientDetails.pastMedicalHistory.map((condition, index) => (
                    <span
                      key={index}
                      className="badge text-white ml-2"
                      style={{ fontSize: "0.9rem",backgroundColor:"#0cc0de" }}
                    >
                      {condition}
                    </span>
                  ))}
                </div>
              </div>
            ) : (
              <p className="mb-0">
                <strong>Past Medical History:</strong> None
              </p>
            )}
          </div>
        </div>
      )}

      {/* Loading Spinner */}
      {isLoading && (
        <div className="d-flex align-items-center justify-content-center my-4">
          <Spinner animation="border" variant="info" />
        </div>
      )}

      {/* No Appointments */}
      {!isLoading && appointments.length === 0 && (
        <div className="text-center">
          <h4>No Past Appointments Found</h4>
        </div>
      )}

      {/* Appointment Cards */}
      <div className="row">
        {appointments.map((appointment) => (
          <div key={appointment._id} className="col-md-6 col-lg-4 mb-4"  onClick={() => navigate.push(`/appointments/${appointment._id}`)}>
            <div className="card shadow-sm appointment-card-past cursor-pointer" style={{
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}>
              <div className="card-body">
                {/* Appointment Status */}
                <div className="mb-3">
                  <span
                    className={`badge ${getStatusBadgeClass(
                      appointment.status
                    )}`}
                  >
                    {appointment.status}
                  </span>
                </div>

                {/* Doctor Details */}
                <h5 className="text-primary mt-3 mb-2">Doctor</h5>
                <div className="d-flex align-items-center mb-3">
                  <img
                    src={appointment.doctor.image}
                    alt="Doctor"
                    className="rounded-circle me-3"
                    style={{
                      width: "60px",
                      height: "60px",
                      objectFit: "cover",
                    }}
                  />
                  <div>
                    <h5 className="ml-2 mb-1">{appointment.doctor.name}</h5>
                  </div>
                </div>

                {/* Appointment Details */}
                <h5 className="text-primary mt-3 mb-2">Appointment Details</h5>
                <p className="mb-1">
                  <FaCalendarAlt className="me-2" />
                  {appointment.timeSlots?.[0] &&
                    new Date(appointment.timeSlots?.[0]).toLocaleDateString()}
                </p>
                <p className="mb-1">
                  <FaClock className="me-2" />
                  {appointment.timeSlots?.[0] &&
                    new Date(appointment.timeSlots?.[0]).toLocaleTimeString()}
                </p>
                <p className="mb-1">
                  <strong>Reason:</strong> {appointment.reasonVisit || "N/A"}
                </p>

                {/* Lab Tests */}
                {appointment.labTest && appointment.labTest.length > 0 && (
                  <>
                    <h5 className="text-primary mt-3 mb-2">Lab Tests</h5>
                    <ul>
                      {appointment.labTest.map((lab) => (
                        <li key={lab._id}>{lab.name}</li>
                      ))}
                    </ul>
                  </>
                )}

                {/* Prescriptions */}
                {appointment.prescriptions &&
                  appointment.prescriptions.length > 0 && (
                    <>
                      <h5 className="text-primary mt-3 mb-2">Prescriptions</h5>
                      <ul>
                        {appointment.prescriptions.map((prescription) => (
                          <li key={prescription._id}>
                            {prescription.name}: {prescription.description}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function getStatusBadgeClass(status) {
  switch (status.toLowerCase()) {
    case "completed":
      return "bg-success text-white";
    case "missed":
      return "bg-danger text-white";
    case "pending":
      return "bg-warning text-dark";
    default:
      return "bg-secondary text-white";
  }
}

export default PatientAppointments;
