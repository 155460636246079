import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import ChangePassword from "../../modal/ChangePassword";
import socketServices from "../../../services/SocketServices";
import IncomingUrgentRequest from "../../modal/IncomingUrgentRequest";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { FaVideo } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { profileUpdateAction } from "../../../store/actions/AuthActions";

const profile =
  "https://coenterprises.com.au/wp-content/uploads/2018/02/male-placeholder-image.jpeg";

const Header = ({ onNote }) => {
  const dispatch = useDispatch();
  const loginType = localStorage.getItem("_anytime_doctor_type_");
  const history = useHistory();
  const [changePassword, setChangePassword] = useState(false);
  const [showUrgentRequestModal, setShowUrgentRequest] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [innerWidth] = useState(window.innerWidth);
  const navigate = useHistory();
  const videoCallShowValue = useSelector((state) => state.session.videoCall);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("_anytime_user_"))
  );
  const profileUpdate = useSelector((state) => state.auth.profileUpdate);

  function initialConnect() {
    setSocketConnected(true);
  }
  const removeListnersOnDisconnect = () => {};

  useEffect(() => {
    if (socketServices?.on) {
      socketServices.emit("disconnectUser");
    }
    socketServices.initializeSocket(initialConnect, removeListnersOnDisconnect);
    return () => socketServices.emit("disconnectUser");
  }, []);

  useEffect(() => {
    if (profileUpdate) {
      setUserDetails(JSON.parse(localStorage.getItem("_anytime_user_")));
      dispatch(profileUpdateAction(false));
    }
  }, [profileUpdate]);

  return (
    <>
      <ChangePassword
        password={changePassword}
        onHide={() => setChangePassword(false)}
      />
      <IncomingUrgentRequest
        show={showUrgentRequestModal}
        socketConnected={socketConnected}
        showModal={() => setShowUrgentRequest(true)}
        onHide={() => setShowUrgentRequest(false)}
      />
      <div className="header" style={{ zIndex: "11" }}>
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left"></div>
              <ul className="navbar-nav header-right">
                {loginType === "Doctor" && (
                  <Dropdown
                    as="li"
                    className="nav-item dropdown notification_dropdown"
                  >
                    {videoCallShowValue && (
                      <Dropdown.Toggle
                        as="a"
                        variant=""
                        className="i-false c-pointer nav-link primary mr-2"
                        onClick={() => {
                          if (localStorage.getItem("VideoCallAppointment")) {
                            navigate.push(
                              "/video-call/" +
                                localStorage.getItem("VideoCallAppointment")
                            );
                          }
                        }}
                        to=""
                      >
                        <FaVideo
                          style={{ color: "#4f4fff", fontSize: "20px" }}
                        />
                      </Dropdown.Toggle>
                    )}
                    {history.location.pathname !== "/chat" && (
                      <Dropdown.Toggle
                        as="a"
                        variant=""
                        className="i-false c-pointer nav-link primary"
                        onClick={() => onNote()}
                        to=""
                      >
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 23 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20.4604 0.848846H3.31682C2.64742 0.849582 2.00565 1.11583 1.53231 1.58916C1.05897 2.0625 0.792727 2.70427 0.791992 3.37367V15.1562C0.792727 15.8256 1.05897 16.4674 1.53231 16.9407C2.00565 17.414 2.64742 17.6803 3.31682 17.681C3.53999 17.6812 3.75398 17.7699 3.91178 17.9277C4.06958 18.0855 4.15829 18.2995 4.15843 18.5226V20.3168C4.15843 20.6214 4.24112 20.9204 4.39768 21.1817C4.55423 21.4431 4.77879 21.6571 5.04741 21.8008C5.31602 21.9446 5.61861 22.0127 5.92292 21.998C6.22723 21.9833 6.52183 21.8863 6.77533 21.7173L12.6173 17.8224C12.7554 17.7299 12.9179 17.6807 13.0841 17.681H17.187C17.7383 17.68 18.2742 17.4993 18.7136 17.1664C19.1531 16.8334 19.472 16.3664 19.6222 15.8359L22.8965 4.05007C22.9998 3.67478 23.0152 3.28071 22.9413 2.89853C22.8674 2.51634 22.7064 2.15636 22.4707 1.8466C22.2349 1.53684 21.9309 1.28565 21.5822 1.1126C21.2336 0.93954 20.8497 0.849282 20.4604 0.848846ZM21.2732 3.60301L18.0005 15.3847C17.9499 15.5614 17.8432 15.7168 17.6964 15.8274C17.5496 15.938 17.3708 15.9979 17.187 15.9978H13.0841C12.5855 15.9972 12.098 16.1448 11.6836 16.4219L5.84165 20.3168V18.5226C5.84091 17.8532 5.57467 17.2115 5.10133 16.7381C4.62799 16.2648 3.98622 15.9985 3.31682 15.9978C3.09365 15.9977 2.87966 15.909 2.72186 15.7512C2.56406 15.5934 2.47534 15.3794 2.47521 15.1562V3.37367C2.47534 3.15051 2.56406 2.93652 2.72186 2.77871C2.87966 2.62091 3.09365 2.5322 3.31682 2.53206H20.4604C20.5905 2.53239 20.7187 2.56274 20.8352 2.62073C20.9516 2.67872 21.0531 2.7628 21.1318 2.86643C21.2104 2.97005 21.2641 3.09042 21.2886 3.21818C21.3132 3.34594 21.3079 3.47763 21.2732 3.60301Z"
                            fill="#000"
                          />
                          <path
                            d="M5.84161 8.42333H10.0497C10.2729 8.42333 10.4869 8.33466 10.6448 8.17683C10.8026 8.019 10.8913 7.80493 10.8913 7.58172C10.8913 7.35851 10.8026 7.14445 10.6448 6.98661C10.4869 6.82878 10.2729 6.74011 10.0497 6.74011H5.84161C5.6184 6.74011 5.40433 6.82878 5.2465 6.98661C5.08867 7.14445 5 7.35851 5 7.58172C5 7.80493 5.08867 8.019 5.2465 8.17683C5.40433 8.33466 5.6184 8.42333 5.84161 8.42333Z"
                            fill="#000"
                          />
                          <path
                            d="M13.4161 10.1066H5.84161C5.6184 10.1066 5.40433 10.1952 5.2465 10.3531C5.08867 10.5109 5 10.725 5 10.9482C5 11.1714 5.08867 11.3854 5.2465 11.5433C5.40433 11.7011 5.6184 11.7898 5.84161 11.7898H13.4161C13.6393 11.7898 13.8534 11.7011 14.0112 11.5433C14.169 11.3854 14.2577 11.1714 14.2577 10.9482C14.2577 10.725 14.169 10.5109 14.0112 10.3531C13.8534 10.1952 13.6393 10.1066 13.4161 10.1066Z"
                            fill="#000"
                          />
                        </svg>
                      </Dropdown.Toggle>
                    )}
                  </Dropdown>
                )}
                <Dropdown as="li" className="nav-item dropdown header-profile">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className={`c-pointer ${
                      innerWidth > 767 ? "nav-link i-false" : ""
                    }`}
                    to=""
                    role="button"
                    data-toggle="dropdown"
                  >
                    <div className="header-info">
                      <span>
                        <strong>{userDetails?.name}</strong>
                      </span>
                    </div>
                    <img src={userDetails.image || profile} width={20} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="right"
                    className="dropdown-menu dropdown-menu-right"
                  >
                    {loginType === "Doctor" && (
                      <Link to="/app-profile" className="dropdown-item ai-icon">
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ml-2">Profile </span>
                      </Link>
                    )}
                    {loginType === "Doctor" && (
                      <Link
                        className="dropdown-item ai-icon"
                        onClick={() => setChangePassword(true)}
                        style={{ backgroundColor: "#fff" }}
                      >
                        <svg
                          fill="#004000"
                          height="20px"
                          width="20px"
                          version="1.1"
                          id="Icon"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 24 24"
                          enable-background="new 0 0 24 24"
                          space="preserve"
                        >
                          <path
                            d="M24,19v-2h-2.14c-0.09-0.36-0.24-0.7-0.42-1.02l1.52-1.52l-1.41-1.41l-1.52,1.52c-0.32-0.19-0.66-0.33-1.02-0.42V12h-2v2.14
                   c-0.36,0.09-0.7,0.24-1.02,0.42l-1.52-1.52l-1.41,1.41l1.52,1.52c-0.19,0.32-0.33,0.66-0.42,1.02H12v2h2.14
                   c0.09,0.36,0.24,0.7,0.42,1.02l-1.52,1.52l1.41,1.41l1.52-1.52c0.32,0.19,0.66,0.33,1.02,0.42V24h2v-2.14
                   c0.36-0.09,0.7-0.24,1.02-0.42l1.52,1.52l1.41-1.41l-1.52-1.52c0.19-0.32,0.33-0.66,0.42-1.02H24z M18,20c-1.1,0-2-0.9-2-2
                   s0.9-2,2-2s2,0.9,2,2S19.1,20,18,20z M11,7.41l3.29,3.29l1.41-1.41L12.41,6L13,5.41l2.29,2.29l1.41-1.41L14.41,4L15,3.41l3.29,3.29
                   l1.41-1.41L16.41,2l0.29-0.29l-1.41-1.41L6.89,8.7C6.19,8.26,5.38,8,4.5,8C2.02,8,0,10.02,0,12.5S2.02,17,4.5,17S9,14.98,9,12.5
                   c0-0.88-0.26-1.69-0.7-2.39L11,7.41z M4.5,15C3.12,15,2,13.88,2,12.5S3.12,10,4.5,10S7,11.12,7,12.5S5.88,15,4.5,15z"
                          />
                        </svg>
                        <span className="ml-2">Change Password </span>
                      </Link>
                    )}
                    <LogoutPage />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
