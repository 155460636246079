import React, { useEffect, useState } from "react";
import { Card, Spinner, Tab, Tabs } from "react-bootstrap";
import {
  addAssistantData,
  addFee,
  getAssistantDoctorListData,
  getProfileFetch,
} from "../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import AssistantDoctor from "../components/cards/AssistantDoctor";

const optionsArray = [{ key: "appointments", label: "Appointments" }];

function Fees() {
  const [loadingData, setLoadingData] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [online, setOnline] = useState("");
  const [offline, setOffline] = useState("");
  let errorsObj = {
    onlineFee: "",
    offlineFee: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  async function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    
    if (online.trim() === "" || +online <= 0) {
      errorObj.onlineFee = "Please enter a valid positive number for online fee";
      error = true;
    }
    if (offline.trim() === "" || +offline <= 0) {
      errorObj.offlineFee = "Please enter a valid positive number for offline fee";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    try {
      setAddLoading(true);
      const response = await addFee({
        onlineFee: +online,
        offlineFee: +offline,
      });
      if (response.data.statusCode === 200) {
        setAddLoading(false);
        toast.success("Added successfully.");
      } else {
        toast.error("Try again");
      }
    } catch (error) {
      toast.error("Try again");
    } finally {
      setAddLoading(false);
    }
  }

  async function getProfileData(){
    setLoadingData(true)
		try{
			const response = await getProfileFetch();
			setOnline(response.data?.data.doctorFee?.onlineFee ?`${response.data?.data.doctorFee?.onlineFee}` :"");
			setOffline(response.data?.data.doctorFee?.offlineFee ? `${response.data?.data.doctorFee?.offlineFee}` : "");
      setLoadingData(false)
		}catch(error){
			console.log(error,"error")
      setLoadingData(false)
		}
	}

  useEffect(() => {
		getProfileData();
	},	[]);

  return (
    <div>
      <div className="form-head d-flex mb-3 align-items-start">
        <div className="mr-auto d-none d-lg-block">
          <h2 className="text-black font-w600 mb-0">Fee structure</h2>
        </div>
      </div>
          <div className="col-12 col-md-5 flex-grow-1">
            <div className="d-flex w-100">
              <Card className="p-3  flex-grow-1">
                <form onSubmit={onLogin}>
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">Online Fee</strong>
                    </label>
                    <input
                      type="number"
                      min="1"
                      className="form-control"
                      value={loadingData ? "Loading..." : online}
                      onChange={(e) => {
                        if (+e.target.value > 0 || e.target.value === '') {
                          setOnline(e.target.value);
                          setErrors({ ...errors, onlineFee: "" });
                        }
                      }}
                      placeholder="Online Fee"
                    />
                    {errors.onlineFee && (
                      <div className="text-danger fs-12">{errors.onlineFee}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="mb-2 ">
                      <strong className="">Offline Fee</strong>
                    </label>
                    <input
                      type="number"
                      min="1"
                      className="form-control"
                      value={loadingData ? "Loading..." : offline}
                      onChange={(e) => {
                        if (+e.target.value > 0 || e.target.value === '') {
                          setOffline(e.target.value);
                          setErrors({ ...errors, offlineFee: "" });
                        }
                      }}
                      placeholder="Offline Fee"
                    />
                    {errors.offlineFee && (
                      <div className="text-danger fs-12">{errors.offlineFee}</div>
                    )}
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary btn-block">
                      {addLoading ? "Processing..." : "Add"}
                    </button>
                  </div>
                </form>
              </Card>
            </div>
          </div>
    </div>
  );
}

export default Fees;
