import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  addMedicalRecord,
  addMedicalRecordVitals,
} from "../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";

function VitalsFormFill({ onHide, show, appointmentId,getAppointmentDetails }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [callData, setCallData] = useState({
    vitals: "",
  });

  async function submit() {
    try {
      setIsLoading(true);
      const response = await addMedicalRecordVitals({
        ...callData,
        appointmentId,
      });
      toast.success("The medical record is added!");
      onHide();
      getAppointmentDetails()
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal className="left-modal" backdrop="static" onHide={onHide} show={show}>
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Add Medical Record</h3>
              <Button
                variant=""
                className="close"
                onClick={() => {
                  if (localStorage.getItem("video-call-show") == "true") {
                    onHide();
                  } else {
                    history.push(`/`);
                  }
                }}
              >
                <span style={{ fontSize: "30px" }}>&times;</span>
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            <form className="d-flex align-items-start flex-column gap-2">
              <div style={{ width: "100%" }}>
                <h6>Vitals</h6>
                <input
                  type="text"
                  value={callData.vitals}
                  onChange={(e) =>
                    setCallData((prev) => ({ ...prev, vitals: e.target.value }))
                  }
                  className="form-control mr-2"
                  name="vitals"
                />
              </div>
            </form>
          </Card.Body>
          <Card.Footer>
            <button
              className="btn btn-primary w-100"
              onClick={submit}
              disabled={isLoading}
            >
              {isLoading ? "Loading" : "Add Data"}
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default VitalsFormFill;
