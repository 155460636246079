import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";
import smallText from "../../../images/Logo/logo_small.png";
import logoText from "../../../images/brand-header-logo.png";

const NavHader = () => {
   const loginType = localStorage.getItem("_anytime_doctor_type_");
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header" style={{zIndex: "12"}}>
         <Link to={loginType === "Doctor" ? "/" : "#"} className="brand-logo">
            <img className="logo-abbr" src={smallText} alt="" width={45} height={45}/>
            <img className="brand-title ml-3" src={logoText} alt="" />
         </Link>

         <div className="nav-control" onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
