import React, { useState } from "react";
import socketServices from "../../../services/SocketServices";
import { useSelector } from "react-redux";
import { FaImage, FaPaperclip } from "react-icons/fa";
import addFileToS3Bucket from "../../../services/Aws";

function ChatInput() {
  const [inputMessage, setInputMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);

  const selectedConversation = useSelector(
    (state) => state.session.selectedConversationDetails
  );

  async function handleFileUpload(file, fileType) {
    setLoading(true);
    try {
      let response;
      if (fileType === "image") {
        response = await addFileToS3Bucket(file, "chat");
      } else {
        // response = await addVideoFileToS3Bucket(file, "chat", setFileUploadProgress);
      }

      console.log(response.Key, "jhdfgsdhjfgahsdgfhagsdf");

      if (response) {
        socketServices.emit("sendMessage", {
          message: file.name,
          type: fileType === "image" ? "Image" : "Document",
          documentLink: `https://demandhealthcare.s3.amazonaws.com/${response.Key}`,
          conversationId: selectedConversation?.conversationId,
          senderType: "doctor",
          receiverId: selectedConversation?.otherPersonId,
        });
      }
      setFileUploadProgress(0);
    } catch (error) {
      console.log(error);
      setFileUploadProgress(0);
    } finally {
      setLoading(false);
      setSelectedFile(null);
      setInputMessage("");
    }
  }

  async function sendMessage(e) {
    e.preventDefault();

    if (selectedFile) {
      console.log("selectedFile", selectedFile);
      const fileType = selectedFile.type.startsWith("image/")
        ? "image"
        : "document";
      await handleFileUpload(selectedFile, fileType);
    } else {
      socketServices.emit("sendMessage", {
        message: inputMessage,
        type: "Text",
        conversationId: selectedConversation?.conversationId,
        senderType: "doctor",
        receiverId: selectedConversation?.otherPersonId,
      });
      setInputMessage("");
    }
  }

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
    setInputMessage(e.target.files[0].name);
  };

  if (selectedConversation)
    return (
      <form onSubmit={sendMessage} className="chat-input-container">
        <input
          type="text"
          className="global-input-field w-100 chat-input-container_input"
          value={inputMessage}
          placeholder={loading ? "Sending..." : "Type your message"}
          onChange={(e) => setInputMessage(e.target.value)}
          disabled={loading}
        />

        <div className="chat-input-actions">
          <label className={`file-upload-label ${loading ? "disabled" : ""}`}>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileSelect}
              style={{ display: "none" }}
              disabled={loading}
            />

            <FaImage className="upload-icon" />
          </label>

          <label className={`file-upload-label ${loading ? "disabled" : ""}`}>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleFileSelect}
              style={{ display: "none" }}
              disabled={loading}
            />
            <FaPaperclip className="upload-icon" />
          </label>
        </div>

        <button
          type="submit"
          className="btn btn-outline-primary chat-input-container_button"
          disabled={(!inputMessage.trim() && !selectedFile) || loading}
        >
          {loading ? "Sending..." : "Send"}
        </button>
      </form>
    );
  else return null;
}

export default ChatInput;
