import React, { useEffect, useRef } from 'react'
import MessageComponent from './MessageComponent';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';

function MessageListScroll({ messageList, isChatFetching }) {
  const bottomRef = useRef(null);

  function getMessageStyles(sender, senderType = "") {
    if (senderType === "doctor") {
      return {
        styles: {
          justifyContent: "flex-end"
        },
        isMyMessage: true,
      };
    } else {
      return {
        styles: {
          justifyContent: "flex-start",
        },
        isMyMessage: false,
      };
    }
  }
  function isProfilePicShown(fromId, senderType) {
    if (!getMessageStyles(fromId, senderType).isMyMessage) {
      return true;
    } else return false;
  }


  function checkIsFirstInTheSeries(
    fromIdLastMessage,
    index,
    senderType
  ) {
    if (index === 0) return true;
    else {
      let secondLastMessage = messageList[index == 0 ? index : index - 1];
      if (secondLastMessage.sender === fromIdLastMessage) {
        if (secondLastMessage?.senderType == senderType) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      bottomRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [messageList]);

  const renderDateSeparator = (date) => (
    <div className="message-date-separator">
      <div className="date-line">
        <span className="date-text">{moment(date).format('MMMM D, YYYY')}</span>
      </div>
    </div>
  );

  const groupMessagesByDate = (messages) => {
    const groups = {};
    messages.forEach(message => {
      const date = moment(message.messageTime).format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
    });
    return groups;
  };

  return (
    <div className='message-list-scroll'>
      {messageList?.length > 0 && (() => {
        const groupedMessages = groupMessagesByDate(messageList);
        return Object.entries(groupedMessages).map(([date, messages]) => (
          <React.Fragment key={date}>
            {renderDateSeparator(date)}
            {messages.map((item, index) => (
              <div
                key={item._id}
                style={
                  getMessageStyles(item.sender, item?.senderType)
                    .styles
                }
                className={`d-flex align-items-start  gap-2 ${isProfilePicShown(
                  item.sender,
                  item?.senderType
                ) &&
                  !checkIsFirstInTheSeries(
                    item.sender,
                    index,
                    item?.senderType
                  ) &&
                  "ml-5"
                  }`}
              >
                {isProfilePicShown(
                  item.sender,
                  item?.senderType
                ) &&
                  checkIsFirstInTheSeries(
                    item.sender,
                    index,
                    item?.senderType
                  ) && (
                    <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png" alt="" className='message-header_image' />
                  )}
                <MessageComponent
                  key={item?._id || item?.localMessageId}
                  type={item.type}
                  documentLink={item?.documentLink}
                  text={item.message}
                  isMyMessage={
                    getMessageStyles(
                      item.sender,
                      item?.senderType
                    ).isMyMessage
                  }
                  createdAt={item.messageTime}
                  isLocalMessage={item?._id ? false : true}
                />
              </div>
            ))}
          </React.Fragment>
        ));
      })()}
      {
        isChatFetching && <div className="d-flex align-items-center justify-content-center"><Spinner animation="border" variant="info" /> </div>
      }
      <div className="pb-3" ref={bottomRef}></div>
    </div>
  )
}

export default MessageListScroll