import React, { useState } from "react";
import { Card } from "react-bootstrap";
import moment from "moment/moment";
import User from "../../../images/DummyUser.png";

function AssistantDoctor({
  name,
  assistantEmail,
  lastAppointmentDate,
}) {
  function capitalizeFirstLetterOfFirstWord(phrase) {
    if (phrase.length === 0) return phrase;
    return phrase.charAt(0).toUpperCase() + phrase.slice(1);
  }

  return (
    <div className="appointment-card-container">
      <Card className="appointment-card row cursor-pointer animate__animated animate__fadeIn">
        <div className="col-3">
          <label htmlFor="" className="appointment-card_label">
            Name
          </label>
          <div className="d-flex align-items-center">
            <img
              src={User}
              alt=""
              className="appointment-card_img"
            />
            <span className="appointment-card_field">
              {capitalizeFirstLetterOfFirstWord(name)}
            </span>
          </div>
        </div>

        <div className="col-2">
          <label htmlFor="" className="appointment-card_label">
            Email
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field">{assistantEmail}</span>
          </div>
        </div>

        <div className="col-3">
          <label htmlFor="" className="appointment-card_label">
            Created date
          </label>
          <div className="d-flex align-items-center">
            <span className="appointment-card_field">
              {moment(lastAppointmentDate).format("DD, MMM yyyy")}
            </span>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default AssistantDoctor;
